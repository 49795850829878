import { render, staticRenderFns } from "./checkbox.html?vue&type=template&id=d8b39ce2&scoped=true&external"
import script from "./checkbox.js?vue&type=script&lang=js&external"
export * from "./checkbox.js?vue&type=script&lang=js&external"
import style0 from "./checkbox.scss?vue&type=style&index=0&id=d8b39ce2&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8b39ce2",
  null
  
)

export default component.exports